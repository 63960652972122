@font-face {
  font-family: 'Packup';
  src: url('/fonts/packup-bold_italic-webfont.woff2') format('woff2'),
    url('/fonts/packup-bold_italic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Packup';
  src: url('/fonts/packup-bold-webfont.woff2') format('woff2'),
    url('/fonts/packup-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Packup';
  src: url('/fonts/packup-italic-webfont.woff2') format('woff2'),
    url('/fonts/packup-italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Packup';
  src: url('/fonts/packup-regular-webfont.woff2') format('woff2'),
    url('/fonts/packup-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
